<template>
    <div class="panoramic-containei">
        <div class="comback-box" @click="comback">
            返回
        </div>
         <iframe :src="panoramicUrl"  
         scrolling="yes" 
         frameborder="0"
         style="height:100vh;width:100%">

         </iframe>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
export default {
    setup(){
        const route = useRoute()
        const router = useRouter()
        const state = reactive({
            panoramicUrl:route.query.panoramicUrl,
        })
        console.log("传来的参数",route.query.panoramicUrl);
        const comback =()=>{
            router.back()
        }
        
        return{
            ...toRefs(state),
            comback
        }
    }
}
</script>

<style lang="scss" >
.panoramic-containei{
    position: relative;
    ::-webkit-scrollbar {
       display: none;
    }
    .comback-box{
        position: absolute;
        height: 36px;
        width: 60px;
        line-height: 36px;
        text-align: center;
        left: 0;
        top: 100px;
        color: white;
        font-size: 14px;
        background:rgba(46, 46, 46, 0.5);
        border-radius: 0 20px 20px 0;
        opacity: 0.5;
    }
}
</style>